/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter, 
} from "@material-tailwind/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import { useRef } from "react";
  import { 
  Button,
  Typography,
} from "@material-tailwind/react";
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
export default function Servicesslide() {
  return (
   <>
   <div class="mx-auto">
      <div  class=" text-gray-600 body-font  ">
      <h3 class="mt-[28px] text-center font-bold lg:text-[18px] sm:text-[15px]"> Explore Our Services !⬇️
     {/*  <a target="_blank" href="/Our_Portfolio">  <Button className="bg-blue-500 ml-[25px]" >   View Clients  <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button> </a> */} </h3>
        </div>
      </div>
   
<Carousel responsive={responsive}  className="z-0 mt-[-8px] overscroll-x-none P-3" >
 

 {/* 1st Slide  */}

 <div className="pl-[-30px] p-[10px]">  
 <Card className="bg-blue-100 mt-6 w-96 p-[25px]">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/website.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
          Website Development 
        </Typography>
        
          
<section className="mt-[16px]">
<div className="grid grid-cols-2 md:grid-cols-3">
  
    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center ">    
       <img src="./assets/icons/wordpress.png" className="w-6 h-6 "  alt="Rounded avatar" style={{fontSize:13}} /> Wordpress </button>
 </div>



<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-6 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:13}}>    
       <img src="./assets/icons/sh.webp" className="w-6 h-6 "  alt="Rounded avatar" /> Shopify  </button>

</div>

 
<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-6 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:13}}>    
       <img src="./assets/icons/laravel.png" className="w-6 h-6 "  alt="Rounded avatar" /> Laravel  </button>

</div>
 
</div>

 
</section>
 

        <Typography className="mt-[6px]">
         ✔ Get: Commerce , Retail, RealEstate Etc. 
       <br></br> ✔ Responsive Dextop, Tablet, Smartphone  

        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
      <a href="/Website_demo"> <button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button></a>


      <a href="/Website_development_hickter"> <button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button></a>


      <a href="/Website_development__apply"> <button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Apply </button></a>
 
</section>
       
      </CardFooter>
    </Card>


 </div>

{/* 2nd Slide  */}

<div className="pl-[-30px] p-[10px]">  
 <Card className="bg-blue-100 mt-6 w-96 p-[25px]">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/app.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
          App Development 
        </Typography>
         
<section className="mt-[6px]">

<div className="grid grid-cols-2 md:grid-cols-3">
  
    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center ">    
       <img src="./assets/icons/react.png" className="w-6 h-6 "  alt="Rounded avatar" style={{fontSize:15}} /> React </button>
 </div>



<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-6 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:15}}>    
       <img src="./assets/icons/swift.png" className="w-6 h-6 "  alt="Rounded avatar" /> Swift  </button>

</div>

 
<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-6 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:15}}>    
       <img src="./assets/icons/flutter.webp" className="w-6 h-6 "  alt="Rounded avatar" /> Flutter  </button>

</div>
 
</div>
 
</section>
 

        <Typography className="mt-[6px]">
        ✔ Build Full-stack Apps For Business 
      <br></br>  ✔ 

        <span className="text-[18px]"> <i style={{fontSize:29}} 
        className="text-green-500 mt-[2px] fa-brands fa-android "></i> 
         <i style={{fontSize:29}} className="mt-[-2px] fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
        </Typography>
  
      </CardBody>
      <CardFooter >

<section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Apply</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>



{/* 3rd Slide  */}


<div className="pl-[-30px] p-[10px]">  
 <Card className="bg-blue-100 mt-6 w-96 p-[25px]">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/digitalmarketing.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
        Digital Marketing 
        </Typography>
        
         

<section className="mt-[6px]">

<div className="grid grid-cols-2 md:grid-cols-3">
  
    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-2 py-2 text-center 
       me-1 mb-2 flex justify-center items-center ">    
       <img src="./assets/icons/googleads.webp" className="w-6 h-6 "  alt="Rounded avatar" style={{fontSize:10}} /> GoogleAds </button>
 </div>



<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:10}}>    
       <img src="./assets/icons/meta.webp" className="w-6 h-6 "  alt="Rounded avatar" /> Meta  </button>

</div>

 
<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:10}}>    
       <img src="./assets/icons/seo.webp" className="w-6 h-6 "  alt="Rounded avatar" /> SEO  </button>

</div>
 
</div>
 
</section>
 

        <Typography className="mt-[6px]">
         ✔ Grow Business Sales & Revenue   
       <br></br> ✔ SEO, Social Media , Etc 

        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Apply</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>


{/* 4th Slide  */}

<div className="pl-[-30px] p-[10px]">  
 <Card className="bg-blue-100 mt-6 w-96 p-[25px]">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/banner.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
        Banner Ads | Design 
        </Typography>
        
         

<section className="mt-[6px]">

<div className="grid grid-cols-2 md:grid-cols-3">
  
    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-2 py-2 text-center 
       me-1 mb-2 flex justify-center items-center ">    
       <img src="./assets/icons/canva.webp" className="w-6 h-6 "  alt="Rounded avatar" style={{fontSize:12}} /> Canva </button>
 </div>



<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:12}}>    
       <img src="./assets/icons/ps.webp" className="w-6 h-6 "  alt="Rounded avatar" /> Photoshop  </button>

</div>

<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:12}}>    
       <img src="./assets/icons/ads.webp" className="w-6 h-6 "  alt="Rounded avatar" /> Ads  </button>

</div>
 
</div>

 
</section>
 

        <Typography className="mt-[6px]">
        ✔ Create Banner Ads Design.
       <br></br>✔ Regular Posting of Ads.
 

        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Apply</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>

{/* 6th Slide  */}


 
<div className="pl-[-30px] p-[10px]">  
 <Card className="bg-blue-100 mt-6 w-96 p-[25px]">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/video.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
        Video Ads | Promotion 
        </Typography>
        
         

<section className="mt-[6px]">

<div className="grid grid-cols-2 md:grid-cols-3">
  
    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-2 py-2 text-center 
       me-1 mb-2 flex justify-center items-center ">    
       <img src="./assets/icons/videoedit.webp" className="w-6 h-6 "  alt="Rounded avatar" style={{fontSize:12}} /> Editing </button>
 </div>
 
<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:12}}>    
       <img src="./assets/icons/videoads.webp" className="w-6 h-6 "  alt="Rounded avatar" /> VideoAds  </button>

</div>

<div>

<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-blue-400/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center " style={{fontSize:12}}>    
       <img src="./assets/icons/ads.webp" className="w-6 h-6 "  alt="Rounded avatar" /> Promotional  </button>

</div>
 
</div> 
</section>
  
        <Typography className="mt-[6px]">
        ✔ Promotional Video and Ads. 
       <br></br> ✔ Regular Editing & Marketing.
 
        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Apply</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>
  
{/* 6th Slide  */}
 
<div className="pl-[-30px] p-[10px]">  
 <Card className="bg-blue-100 mt-6 w-96 p-[25px]">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/digitalmarketing.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
     Logo Design | Creation 
        </Typography>
         
<section className="mt-[6px]">

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-white  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-white dark:focus:ring-white shadow-lg shadow-teal-900/40 dark:shadow-lg dark:shadow-white-800/80 font-medium rounded-lg text-sm px-3 py-3 text-center me-3 mb-0">   
<img src="./assets/icons/apple.webp" class="w-11 h-11 rounded-full"  alt="Rounded avatar" />  </button>


<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-white  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-white dark:focus:ring-white shadow-lg shadow-teal-900/40 dark:shadow-lg dark:shadow-white-800/80 font-medium rounded-lg text-sm px-3 py-3 text-center me-3 mb-0">   
<img src="./assets/icons/md.webp" class="w-11 h-11 rounded-full"  alt="Rounded avatar" />  </button>


<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-white  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-white dark:focus:ring-white shadow-lg shadow-teal-900/40 dark:shadow-lg dark:shadow-white-800/80 font-medium rounded-lg text-sm px-3 py-3 text-center me-3 mb-0">   
<img src="./assets/icons/nike.webp" class="w-11 h-11 rounded-full"  alt="Rounded avatar" />  </button>

 
</section>
 

        <Typography className="mt-[6px]">
         ✔ Grow Business With Brand Value 
        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Apply</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>
 
</Carousel>

   </>
  )
}
 

