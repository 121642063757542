/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
// eslint-disable-next-line no-unused-vars
import ReactPlayer from 'react-player' 


export default function Aboutusection() {
  return (
  <> 
  <section>
  <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-3 lg:py-3 lg:mb-3">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div className="relative h-64 overflow-hidden rounded-lg sm:h-8 lg:order-last lg:h-full">
     
      <div className='mr-5 player-wrapper'>
      <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="https://ik.imagekit.io/hickterapp/Hickter.com/blog/11.png?updatedAt=1714332829738?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=7350&amp;w=1260"
            alt=""
          />
        </div>

      </div>

      <div className="ml-6 mt-6 ">
        <h2 className="text-[18px] font-bold sm:text-4xl"> About <a className="text-blue-700" > Hickter  </a> </h2>

        <p className="mt-4 text-gray-600">Hickter: Utilising Technology and the Internet to Solve Life's Problems. We Utilise Technology and the Internet to Create Innovative Products (Apps, Web & Gadgets). At Hickter, we believe that emerging technology could offer real human problems better answers than in the past. Until a brilliant idea is generated and put into action, it is nothing. Come work with us if you think that innovation and making amazing goods are important.

<br></br>Create apps and B2B, B2C, and D2C online marketplace shopfronts.
Our industry-leading digital commerce, ERP, customizable website, POS, and CRM solutions assist businesses. We have helped more than 100 clients worldwide manage difficult operations and expand their businesses over the past seven years.
 
        </p>
     
<a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Contact"
>
  <span class="text-sm font-medium"> 
Contact Us Now 
      </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="https://res.cloudinary.com/dailyleadmin/image/upload/v1637069073/Logos/MAIN_DAILYLE_xitqhf.png"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
 
      </div>
    </div> 
  </div>

  <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
      <div>
          <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/mm.png?updatedAt=1714333175385?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
            alt=""
          />
        </div>
        <div className="lg:pr-10">
          <a
            href="/"
            aria-label="Go Home"
            title="Logo"
            className="inline-block mb-5"
          >
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
            <i style={{fontSize:40}} class="fa-solid fa-bullseye"></i>
            </div>
          </a>
          <h5 className="mb-4 text-4xl font-extrabold leading-none">
          Our Mission
 
          </h5>
          <p className="mb-6 text-gray-900">
          We don't only rely on one service, so you can obtain a comprehensive solution here and avoid having to go elsewhere to perform various tasks at different phases of your business. This is our primary distinction from other technology solutions provider firms.
We can understand your business better than anybody else since we manage your entire company in one location, which allows us to grow more than anyone else. 
          In addition to providing a full package for everyone to scale and launch their own business at the lowest prices you've ever imagined at Hickter, we also develop cutting-edge custom apps and websites using the newest technologies that can benefit society, individuals, and businesses to grow to the next level without worrying about technology presence.
          </p>
          <hr className="mb-5 border-gray-300" />
          
        </div>
       
      </div>
    </div>

</section>
  </>
  )
}

 